import React, { useState, useEffect } from "react";

const Chat = ({ userId, activeConversation }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");

  const fetchMessages = async (conversationId) => {
    try {
      const response = await fetch(
        `https://x8ki-letl-twmt.n7.xano.io/api:5Jjba3Jn/messagesOfConversation?conversations_id=${conversationId}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      if (!response.ok) {
        throw new Error(`Failed to fetch messages: ${response.statusText}`);
      }
      const messages = await response.json();
      setMessages(messages);
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  useEffect(() => {
    if (activeConversation) {
      fetchMessages(activeConversation.id);
    } else {
      setMessages([]);
    }
  }, [activeConversation]);

  const sendMessage = async () => {
    if (!input.trim()) return;

    const activeQuery = input;
    setInput("");

    try {
      let conversationId = activeConversation?.id;

      // STEP 0: Save activeQuery in localStorage
      localStorage.setItem("activeQuery", activeQuery);

      if (!conversationId) {
        // STEP 1: Create a new conversation
        const newConversationResponse = await fetch(
          "https://x8ki-letl-twmt.n7.xano.io/api:ANgpKYYs/conversations",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              user_id: userId,
              convHist: "",
              convoTitle: "New Conversation",
            }),
          }
        );

        if (!newConversationResponse.ok) {
          throw new Error("Failed to create a new conversation");
        }

        const newConversation = await newConversationResponse.json();
        conversationId = newConversation.id;
      }

      // STEP 2: Update user statsReq flag
      await fetch(`https://x8ki-letl-twmt.n7.xano.io/api:KeClO-2A/user/${userId}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: userId,
          statsReq: true,
          summaryReq: false,
          linkReq: false,
        }),
      });

      // STEP 3: Create a new message
      const messageResponse = await fetch(
        "https://x8ki-letl-twmt.n7.xano.io/api:5Jjba3Jn/messages",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            query: activeQuery,
            inference: "",
            equation: "",
            conversations_id: conversationId,
          }),
        }
      );

      if (!messageResponse.ok) {
        throw new Error("Failed to create message");
      }

      const newMessage = await messageResponse.json();
      setMessages((prev) => [...prev, newMessage]);

      // STEP 4: Handle ScholarSphere and OpenAI integration
      const scholarSphereEquation = await handleScholarSphereIntegration(newMessage);

      // Update the conversation history and message inference
      const openAIResponse = await handleOpenAIIntegration(newMessage, scholarSphereEquation);
      await updateConversationAndMessage(conversationId, newMessage.id, openAIResponse, scholarSphereEquation);

      // Refresh the active messages
      fetchMessages(conversationId);
    } catch (error) {
      console.error("Error handling send message flow:", error);
    }
  };

  const handleScholarSphereIntegration = async (message) => {
    try {
      const response = await fetch("https://api.scholarsphere.ca/infer", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ query: message.query }),
      });

      if (response.ok) {
        const data = await response.json();
        return data.equation || "";
      }
    } catch (error) {
      console.error("Error with ScholarSphere integration:", error);
    }
    return ""; // Fallback if ScholarSphere fails
  };

  const handleOpenAIIntegration = async (message, equation) => {
    const prompt = createPrompt(message, equation);
    try {
      const response = await fetch("https://bots.scholarsphere.ca/api/v1/prediction/84698a6b-1dc0-4599-983c-9bb6aff64a6d", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ question: prompt }),
      });

      if (response.ok) {
        const data = await response.json();
        return data.response || "Default OpenAI Response";
      }
    } catch (error) {
      console.error("Error with OpenAI integration:", error);
    }
    return "Default OpenAI Response"; // Fallback response
  };

  const updateConversationAndMessage = async (conversationId, messageId, inference, equation) => {
    // Update conversation history
    await fetch("https://x8ki-letl-twmt.n7.xano.io/api:ANgpKYYs/convAppendHist", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        conversations_id: conversationId,
        newHistory: inference,
      }),
    });

    // Update message
    await fetch(`https://x8ki-letl-twmt.n7.xano.io/api:5Jjba3Jn/messages/${messageId}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        inference,
        equation,
      }),
    });
  };

  const createPrompt = (message, equation) => {
    const basePrompt = `The user asked: ${message.query}.`;
    if (equation) {
      return `${basePrompt} Here is the equation: ${equation}. Provide an insightful response.`;
    }
    return `${basePrompt} Provide an insightful response.`;
  };

  return (
    <div className="flex flex-col h-full bg-white dark:bg-gray-900 p-4 rounded-lg shadow-md">
      <div className="flex-grow overflow-y-auto mb-4">
        {messages.map((message) => (
          <div key={message.id} className="mb-2 flex flex-col">
            <div className="ml-auto bg-blue-500 text-white p-2 rounded max-w-xs text-right">
              {message.query}
            </div>
            <div className="mr-auto bg-gray-300 text-black p-2 rounded max-w-xs text-left">
              {message.inference}
            </div>
          </div>
        ))}
      </div>
      <div className="flex">
        <input
          className="flex-grow px-4 py-2 border rounded-l-lg"
          type="text"
          placeholder="Type your message..."
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && sendMessage()}
        />
        <button className="px-6 py-2 bg-blue-600 text-white rounded-r-lg" onClick={sendMessage}>
          Send
        </button>
      </div>
    </div>
  );
};

export default Chat;
