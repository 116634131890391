import React, { useState } from 'react';
import { FcGoogle } from 'react-icons/fc';
import redirectUri from '../config';
import { useNavigate } from 'react-router-dom';

const SignInModal = ({ onClose, openSignUpModal }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const saveUserDataToLocalStorage = (authToken, userData) => {
    const data = {
      token: authToken,
      id: userData.id,
      email: userData.email,
      created_at: userData.created_at,
      firstName: userData.firstName,
      lastName: userData.lastName,
      username: userData.username,
      statsReq: userData.statsReq,
      summaryReq: userData.summaryReq,
      linkReq: userData.linkReq,
      dataLink: userData.dataLink,
      profileImage: userData.profileImage,
    };
    console.log('Saving user data to localStorage:', data);
    localStorage.setItem('auth', JSON.stringify(data));
    window.dispatchEvent(new Event('storage'));
  };

  const fetchAndSaveUserData = async (authToken) => {
    try {
      const userResponse = await fetch(
        'https://x8ki-letl-twmt.n7.xano.io/api:_Lljnuaf/auth/me',
        {
          method: 'GET',
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );

      if (!userResponse.ok) {
        throw new Error(`Failed to fetch user details: ${userResponse.statusText}`);
      }

      const userData = await userResponse.json();
      console.log('User data retrieved via /auth/me:', userData);

      saveUserDataToLocalStorage(authToken, userData);
    } catch (error) {
      console.error('Error fetching user data via /auth/me:', error);
    }
  };

  const handleGoogleInit = async () => {
    try {
      const response = await fetch(
        `https://x8ki-letl-twmt.n7.xano.io/api:kwlhVT3p/oauth/google/init?redirect_uri=${encodeURIComponent(
          redirectUri
        )}`,
        { method: 'GET' }
      );

      if (response.ok) {
        const { authUrl } = await response.json();
        console.log('Redirecting to Google login:', authUrl);
        window.location.href = authUrl;
      } else {
        console.error('Failed to initiate Google login:', response.statusText);
      }
    } catch (error) {
      console.error('Error initiating Google login:', error);
    }
  };

  const handleEmailLogin = async () => {
    console.log('Attempting email/password login with:', { email, password });

    try {
      const response = await fetch(
        'https://x8ki-letl-twmt.n7.xano.io/api:_Lljnuaf/auth/login',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email, password }),
        }
      );

      if (response.ok) {
        const { authToken } = await response.json();
        console.log('Email login successful. AuthToken:', authToken);
        await fetchAndSaveUserData(authToken);
        navigate('/dashboard');
      } else {
        console.error('Email login failed:', response.statusText);
        alert('Invalid email or password.');
      }
    } catch (error) {
      console.error('Error during email login:', error);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-8 w-full max-w-md relative shadow-lg">
        {/* Close Button */}
        <button
          className="absolute top-4 right-4 text-gray-600 hover:text-black"
          onClick={onClose}
        >
          ✕
        </button>

        {/* Modal Header */}
        <div className="mb-6 flex flex-col items-center">
          <img src="/logo.png" alt="logo" className="mb-4 h-10 w-auto" />
          <h2 className="text-2xl font-bold mb-2 text-center">Log In</h2>
        </div>

        {/* Email/Password Login */}
        <div className="space-y-4">
          <input
            type="email"
            placeholder="Enter your email"
            className="w-full px-4 py-2 border rounded-lg"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Enter your password"
            className="w-full px-4 py-2 border rounded-lg"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            className="w-full px-6 py-3 bg-blue-600 text-white font-semibold text-lg rounded-lg shadow-md hover:bg-blue-700"
            onClick={handleEmailLogin}
          >
            Login
          </button>
          <button
            className="flex items-center justify-center w-full px-6 py-3 border border-gray-300 text-gray-600 font-semibold text-lg rounded-lg shadow-md hover:bg-gray-100"
            onClick={handleGoogleInit}
          >
            <FcGoogle className="mr-3 text-xl" />
            Continue with Google
          </button>
        </div>

        {/* Footer */}
        <div className="mt-6 text-center text-sm text-gray-500">
          <p>
            Don’t have an account yet?{' '}
            <span
              className="text-blue-600 font-medium cursor-pointer hover:underline"
              onClick={openSignUpModal}
            >
              Sign up
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignInModal;
