import React, { useState, useEffect } from "react";
import Chat from "./Chat";
import ProfileSettings from "./ProfileSettings";
import { BsToggleOn, BsToggleOff } from "react-icons/bs";
import { FiLogOut } from "react-icons/fi"; // Import Logout icon
import { changeUserFlags } from "../api"; // Assuming API helper for updating user flags

const Dashboard = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [conversations, setConversations] = useState([]);
  const [userInfo, setUserInfo] = useState({
    id: null,
    firstName: "User",
    lastName: "",
    email: "",
    statsReq: false,
    summaryReq: false,
    linkReq: false,
    profileImage: "/default-profile.png",
  });
  const [activeConversation, setActiveConversation] = useState(null);
  const [showProfileSettings, setShowProfileSettings] = useState(false); // New state

  useEffect(() => {
    const authData = JSON.parse(localStorage.getItem("auth"));
    if (authData && authData.id) {
      setUserInfo((prev) => ({
        ...prev,
        id: authData.id,
        firstName: authData.firstName || prev.firstName,
        lastName: authData.lastName || "",
        email: authData.email || "",
        statsReq: authData.statsReq || false,
        summaryReq: authData.summaryReq || false,
        linkReq: authData.linkReq || false,
        profileImage: authData.profileImage || "/default-profile.png",
      }));
      fetchConversations(authData.id);
    } else {
      console.error("Auth data not found in localStorage. Redirecting to login...");
      window.location.href = "/";
    }
  }, []);

  const fetchConversations = async (userId) => {
    try {
      const response = await fetch(
        `https://x8ki-letl-twmt.n7.xano.io/api:ANgpKYYs/conversationsUser?id=${userId}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response.ok) {
        const data = await response.json();
        const sortedConversations = data.sort((a, b) => b.created_at - a.created_at);
        setConversations(sortedConversations);
      } else {
        console.error("Failed to fetch conversations:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching conversations:", error);
    }
  };

  const updateToggle = async (toggleName) => {
    try {
      const updatedValue = !userInfo[toggleName];
      const updatedUserInfo = { ...userInfo, [toggleName]: updatedValue };

      await changeUserFlags(userInfo.id, {
        statsReq: toggleName === "statsReq" ? updatedValue : userInfo.statsReq,
        summaryReq: toggleName === "summaryReq" ? updatedValue : userInfo.summaryReq,
        linkReq: toggleName === "linkReq" ? updatedValue : userInfo.linkReq,
      });

      setUserInfo(updatedUserInfo);
    } catch (error) {
      console.error("Error updating toggle:", error);
    }
  };

  const handleConversationClick = (conversationId) => {
    setActiveConversation({ id: conversationId });
    setShowProfileSettings(false); // Hide profile settings when a conversation is clicked
  };

  const handleProfileClick = () => {
    setShowProfileSettings(true); // Show profile settings when user clicks on their name
  };

  return (
    <div className={darkMode ? "dark" : ""}>
      <div className="flex">
        {/* Sidebar */}
        <div className="w-64 bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-gray-200 flex flex-col h-screen shadow-md">
          {/* Logo Section */}
          <div className="p-4 flex flex-col items-center">
            <img src="/logo.png" alt="InsightCore Logo" className="h-12 w-auto mb-2 object-contain" />
            <p
              className="text-sm text-gray-600 dark:text-gray-400 text-center cursor-pointer underline hover:text-blue-500"
              onClick={handleProfileClick}
            >
              Welcome, <span className="font-semibold">{userInfo.firstName}</span>
            </p>
          </div>

          {/* Chat Options */}
          <div className="p-4 space-y-4">
            {["statsReq", "summaryReq", "linkReq"].map((option, index) => (
              <div className="flex items-center justify-between" key={index}>
                <span className="text-sm capitalize">{option.replace("Req", "")}</span>
                {userInfo[option] ? (
                  <BsToggleOn
                    className="text-blue-500 cursor-pointer text-xl"
                    onClick={() => updateToggle(option)}
                  />
                ) : (
                  <BsToggleOff
                    className="text-gray-500 cursor-pointer text-xl"
                    onClick={() => updateToggle(option)}
                  />
                )}
              </div>
            ))}
          </div>

          {/* Conversations */}
          <div className="p-4 flex-grow overflow-y-auto">
            <p className="text-sm font-semibold mb-2">Conversations</p>
            {conversations.length > 0 ? (
              <ul className="space-y-2">
                {conversations.map((conversation) => (
                  <li
                    key={conversation.id}
                    className="px-4 py-2 bg-gray-200 dark:bg-gray-700 rounded shadow text-sm hover:bg-gray-300 dark:hover:bg-gray-600 cursor-pointer"
                    onClick={() => handleConversationClick(conversation.id)}
                  >
                    {conversation.convoTitle || "Untitled Conversation"}
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-sm text-gray-600 dark:text-gray-400">No conversations yet.</p>
            )}
          </div>

          {/* Dark Mode Toggle */}
          <div className="p-4">
            <div className="flex items-center justify-between">
              <span className="text-sm">Dark Mode</span>
              {darkMode ? (
                <BsToggleOn
                  className="text-blue-500 cursor-pointer text-xl"
                  onClick={() => setDarkMode(false)}
                />
              ) : (
                <BsToggleOff
                  className="text-gray-500 cursor-pointer text-xl"
                  onClick={() => setDarkMode(true)}
                />
              )}
            </div>
          </div>

          {/* User Profile and Logout */}
          <div className="p-4 border-t border-gray-300 dark:border-gray-700">
            <div className="flex items-center space-x-4">
              <img
                src={userInfo.profileImage?.url || "/default-profile.png"} // Update to use the correct URL path
                alt="User Profile"
                className="h-10 w-10 rounded-full border border-gray-300 dark:border-gray-600 object-cover"
              />
              <div>
                <p className="text-sm font-medium">{userInfo.firstName}</p>
                <p className="text-xs text-gray-600 dark:text-gray-400">{userInfo.email}</p>
              </div>
            </div>
            <button
              className="mt-2 w-full px-4 py-2 bg-red-500 text-white text-sm font-medium rounded hover:bg-red-600"
              onClick={() => {
                localStorage.removeItem("auth");
                window.location.href = "/";
              }}
            >
              <FiLogOut className="inline-block mr-2" />
              Logout
            </button>
          </div>          
        </div>

        {/* Main Content */}
        <div className="flex-1 p-4">
          {showProfileSettings ? (
            <ProfileSettings userInfo={userInfo} />
          ) : (
            <Chat userId={userInfo.id} activeConversation={activeConversation} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
