import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import GoogleOAuthHandler from './components/GoogleOAuthHandler';
import Hero from './components/Hero';
import ProfileSettings from './components/ProfileSettings'; // Import ProfileSettings

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    const checkAuthStatus = () => {
      const authData = localStorage.getItem('auth');
      console.log('App checking auth status. LocalStorage:', authData);

      if (authData) {
        const parsedData = JSON.parse(authData);
        setIsAuthenticated(true);
        setUserInfo(parsedData); // Set user info globally
      } else {
        setIsAuthenticated(false);
        setUserInfo({});
      }
    };

    checkAuthStatus();

    // Listen for storage updates (e.g., when token is saved)
    window.addEventListener('storage', checkAuthStatus);

    return () => {
      window.removeEventListener('storage', checkAuthStatus);
    };
  }, []);

  // Function to update user info globally
  const handleUpdateUser = (updatedUserInfo) => {
    setUserInfo(updatedUserInfo);
    localStorage.setItem('auth', JSON.stringify(updatedUserInfo)); // Persist changes
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Hero />} />
        <Route
          path="/dashboard"
          element={
            isAuthenticated ? (
              <Dashboard userInfo={userInfo} onUpdateUser={handleUpdateUser} />
            ) : (
              <Hero />
            )
          }
        />
        <Route
          path="/assets/oauth/google/index.html"
          element={<GoogleOAuthHandler />}
        />
        <Route
          path="/profile-settings"
          element={
            isAuthenticated ? (
              <ProfileSettings userInfo={userInfo} onUpdateUser={handleUpdateUser} />
            ) : (
              <Hero />
            )
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
