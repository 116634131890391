import React, { useState } from "react";

const ProfileSettings = ({ userInfo }) => {
  const [firstName, setFirstName] = useState(userInfo.firstName || "");
  const [lastName, setLastName] = useState(userInfo.lastName || "");
  const [email, setEmail] = useState(userInfo.email || "");
  const [username, setUsername] = useState(userInfo.username || "");
  const [profileImage, setProfileImage] = useState(userInfo.profileImage?.url || "/default-profile.png"); // Handle URL from backend
  const [imageFile, setImageFile] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      let imageUrl = profileImage;

      // Step 1: Upload image if a new file is selected
      if (imageFile) {
        const formData = new FormData();
        formData.append("file", imageFile);
        formData.append("user_id", userInfo.id);

        const uploadResponse = await fetch(
          "https://x8ki-letl-twmt.n7.xano.io/api:KeClO-2A/upload/image",
          {
            method: "POST",
            body: formData,
          }
        );

        if (!uploadResponse.ok) {
          throw new Error("Failed to upload image");
        }

        const uploadData = await uploadResponse.json();
        imageUrl = uploadData.url || uploadData.path; // Get URL or path from API response
        setProfileImage(imageUrl); // Update state for UI
      }

      // Step 2: Update user profile
      const response = await fetch(
        `https://x8ki-letl-twmt.n7.xano.io/api:KeClO-2A/user/${userInfo.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            firstName,
            lastName,
            email,
            username,
            profileImage: imageUrl, // Update with the uploaded image URL
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update profile");
      }

      alert("Profile updated successfully!");
    } catch (error) {
      console.error("Error updating profile:", error);
      alert("Error updating profile: " + error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section className="h-screen bg-gray-100">
      <form
        onSubmit={handleSubmit}
        className="container max-w-2xl mx-auto shadow-md md:w-3/4 bg-white rounded-lg"
      >
        <div className="p-4 border-b-2 border-indigo-400 rounded-t-lg bg-gray-50">
          <div className="max-w-sm mx-auto md:w-full md:mx-0">
            <div className="inline-flex items-center space-x-4">
              <img
                alt="Profile"
                src={profileImage}
                className="mx-auto object-cover rounded-full h-16 w-16 border border-gray-300"
              />
              <div>
                <h1 className="text-gray-600 text-lg font-semibold">
                  Edit Profile
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="space-y-6 p-4">
          {/* Account Details */}
          <div className="items-center w-full space-y-4 md:inline-flex">
            <h2 className="md:w-1/3 text-gray-600 text-sm font-medium">Account</h2>
            <div className="md:w-2/3">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="rounded-lg border border-gray-300 w-full py-2 px-4 text-gray-700 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-400"
                placeholder="Email"
              />
            </div>
          </div>
          <hr />
          {/* Personal Info */}
          <div className="items-center w-full space-y-4 md:inline-flex">
            <h2 className="md:w-1/3 text-gray-600 text-sm font-medium">Personal Info</h2>
            <div className="md:w-2/3 space-y-4">
              <input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="rounded-lg border border-gray-300 w-full py-2 px-4 text-gray-700 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-400"
                placeholder="First Name"
              />
              <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="rounded-lg border border-gray-300 w-full py-2 px-4 text-gray-700 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-400"
                placeholder="Last Name"
              />
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="rounded-lg border border-gray-300 w-full py-2 px-4 text-gray-700 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-400"
                placeholder="Username"
              />
            </div>
          </div>
          <hr />
          {/* Profile Image Upload */}
          <div className="items-center w-full space-y-4 md:inline-flex">
            <h2 className="md:w-1/3 text-gray-600 text-sm font-medium">Profile Image</h2>
            <div className="md:w-2/3">
              <input
                type="file"
                onChange={handleImageChange}
                className="rounded-lg border border-gray-300 w-full py-2 px-4 text-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-400"
              />
            </div>
          </div>
          <hr />
          {/* Submit Button */}
          <div className="w-full text-center">
            <button
              type="submit"
              className="py-2 px-4 bg-indigo-600 text-white rounded-lg shadow-md hover:bg-indigo-700 transition duration-200"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Saving..." : "Save Changes"}
            </button>
          </div>
        </div>
      </form>
    </section>
  );
};

export default ProfileSettings;
