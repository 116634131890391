import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const SignUpModal = ({ onClose, openSignInModal }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const saveUserDataToLocalStorage = (authToken, userData) => {
    const data = {
      token: authToken,
      id: userData.id,
      email: userData.email,
      created_at: userData.created_at,
      firstName: userData.firstName,
      lastName: userData.lastName,
      username: userData.username,
      statsReq: userData.statsReq,
      summaryReq: userData.summaryReq,
      linkReq: userData.linkReq,
      dataLink: userData.dataLink,
      profileImage: userData.profileImage,
    };
    console.log('Saving user data to localStorage:', data);
    localStorage.setItem('auth', JSON.stringify(data));
    window.dispatchEvent(new Event('storage'));
  };

  const fetchAndSaveUserData = async (authToken) => {
    try {
      const userResponse = await fetch(
        'https://x8ki-letl-twmt.n7.xano.io/api:_Lljnuaf/auth/me',
        {
          method: 'GET',
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );

      if (!userResponse.ok) {
        throw new Error(`Failed to fetch user details: ${userResponse.statusText}`);
      }

      const userData = await userResponse.json();
      console.log('User data retrieved via /auth/me:', userData);

      saveUserDataToLocalStorage(authToken, userData);
    } catch (error) {
      console.error('Error fetching user data via /auth/me:', error);
    }
  };

  const handleSignUp = async () => {
    if (!firstName || !lastName || !email || !password) {
      alert('Please fill in all required fields.');
      return;
    }

    if (!/^(?=.*[A-Za-z])(?=.*\d).{8,}$/.test(password)) {
      alert('Password must be at least 8 characters long and include a letter and a number.');
      return;
    }

    try {
      // Step 1: Sign up the user
      const signUpResponse = await fetch(
        'https://x8ki-letl-twmt.n7.xano.io/api:_Lljnuaf/auth/signup',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ firstName, lastName, email, password }),
        }
      );

      if (!signUpResponse.ok) {
        console.error('Sign up failed:', signUpResponse.statusText);
        alert('Sign up failed. Please try again.');
        return;
      }

      const { authToken } = await signUpResponse.json();
      console.log('Sign up successful. AuthToken received:', authToken);

      // Step 2: Fetch user details and save them
      await fetchAndSaveUserData(authToken);

      // Step 3: Navigate to the dashboard
      navigate('/dashboard');
    } catch (error) {
      console.error('Sign up error:', error);
      alert('An error occurred. Please try again later.');
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-8 w-full max-w-md relative shadow-lg">
        {/* Close Button */}
        <button
          className="absolute top-4 right-4 text-gray-600 hover:text-black"
          onClick={onClose}
        >
          ✕
        </button>

        {/* Modal Header */}
        <div className="mb-6 flex flex-col items-center">
          <img
            src="/logo.png" // Replace with your logo path
            alt="logo"
            className="mb-4 h-10 w-auto"
          />
          <h2 className="text-2xl font-bold mb-2 text-center">Sign Up</h2>
          <p className="text-sm text-gray-500 text-center">
            Create an account to get started.
          </p>
        </div>

        {/* Sign Up Form */}
        <div className="space-y-4">
          <input
            type="text"
            placeholder="First Name"
            className="w-full px-4 py-2 border rounded-lg"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <input
            type="text"
            placeholder="Last Name"
            className="w-full px-4 py-2 border rounded-lg"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <input
            type="email"
            placeholder="Email"
            className="w-full px-4 py-2 border rounded-lg"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            className="w-full px-4 py-2 border rounded-lg"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <p className="text-sm text-gray-500">
            Password must be at least 8 characters long and include a letter and a number.
          </p>
          <button
            className="w-full px-6 py-3 bg-blue-600 text-white font-semibold text-lg rounded-lg shadow-md hover:bg-blue-700"
            onClick={handleSignUp}
          >
            Sign Up
          </button>
        </div>

        {/* Footer */}
        <div className="mt-6 text-center text-sm text-gray-500">
          <p>
            Already have an account?{' '}
            <span
              className="text-blue-600 font-medium cursor-pointer hover:underline"
              onClick={openSignInModal}
            >
              Log in
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignUpModal;
