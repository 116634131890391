import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import redirectUri from '../config';

const GoogleOAuthHandler = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleOAuthCallback = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');

      if (!code) {
        console.error('No OAuth code found. Redirecting to home.');
        navigate('/');
        return;
      }

      try {
        const response = await fetch(
          `https://x8ki-letl-twmt.n7.xano.io/api:kwlhVT3p/oauth/google/continue?redirect_uri=${encodeURIComponent(
            redirectUri
          )}&code=${code}`,
          { method: 'GET' }
        );

        if (!response.ok) {
          console.error('Failed to process Google OAuth:', response.statusText);
          navigate('/');
          return;
        }

        const { token } = await response.json();

        if (token) {
          console.log('Google OAuth successful. Retrieved token:', token);
          await fetchAndSaveUserData(token);
          navigate('/dashboard');
        } else {
          console.error('Invalid response from Google OAuth endpoint.');
          navigate('/');
        }
      } catch (error) {
        console.error('Error during Google OAuth callback processing:', error);
        navigate('/');
      }
    };

    const fetchAndSaveUserData = async (authToken) => {
      try {
        const userResponse = await fetch(
          'https://x8ki-letl-twmt.n7.xano.io/api:_Lljnuaf/auth/me',
          {
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` },
          }
        );

        if (!userResponse.ok) {
          throw new Error(`Failed to fetch user details: ${userResponse.statusText}`);
        }

        const userData = await userResponse.json();
        console.log('User data retrieved via /auth/me:', userData);

        localStorage.setItem('auth', JSON.stringify({ token: authToken, ...userData }));
        window.dispatchEvent(new Event('storage'));
      } catch (error) {
        console.error('Error fetching user data via /auth/me:', error);
      }
    };

    handleOAuthCallback();
  }, [navigate]);

  return <h1>Authenticating, please wait...</h1>;
};

export default GoogleOAuthHandler;
