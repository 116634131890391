const BASE_URL = "https://x8ki-letl-twmt.n7.xano.io/api";

export const changeUserFlags = async (userId, flagStates) => {
  const url = `${BASE_URL}:KeClO-2A/user/${userId}`;
  const body = {
    id: userId,
    ...flagStates, // Spread the passed flag states
  };

  try {
    const response = await fetch(url, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error(`Failed to change user flags: ${response.statusText}`);
    }
    console.log("User flags updated successfully.");
  } catch (error) {
    console.error("Error changing user flags:", error);
  }
};


export const createConversation = async (userId) => {
  const url = `${BASE_URL}:ANgpKYYs/conversations`;
  const body = {
    user_id: userId,
    convHist: "",
    convoTitle: "New Conversation",
  };

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error(`Failed to create conversation: ${response.statusText}`);
    }
    const data = await response.json();
    console.log("New conversation created:", data);
    return data;
  } catch (error) {
    console.error("Error creating conversation:", error);
    return null;
  }
};

export const addMessage = async (query, conversationId) => {
  const url = `${BASE_URL}:5Jjba3Jn/messages`;
  const body = {
    query,
    inference: "",
    equation: "",
    conversations_id: conversationId,
  };

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error(`Failed to add message: ${response.statusText}`);
    }
    const data = await response.json();
    console.log("New message created:", data);
    return data;
  } catch (error) {
    console.error("Error adding message:", error);
    return null;
  }
};

export const updateConversationHistory = async (
  conversationId,
  userId,
  newHistory
) => {
  const url = `${BASE_URL}:ANgpKYYs/convAppendHist`;
  const body = {
    conversations_id: conversationId,
    user_id: userId,
    newHistory,
  };

  try {
    const response = await fetch(url, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error(
        `Failed to update conversation history: ${response.statusText}`
      );
    }
    console.log("Conversation history updated successfully.");
  } catch (error) {
    console.error("Error updating conversation history:", error);
  }
};

export const updateMessage = async (
  messageId,
  query,
  inference,
  equation,
  conversationId
) => {
  const url = `${BASE_URL}:5Jjba3Jn/messages/${messageId}`;
  const body = {
    messages_id: messageId,
    query,
    inference,
    equation,
    conversations_id: conversationId,
  };

  try {
    const response = await fetch(url, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error(`Failed to update message: ${response.statusText}`);
    }
    console.log("Message updated successfully.");
  } catch (error) {
    console.error("Error updating message:", error);
  }
};
