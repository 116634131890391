import React, { useState } from 'react';
import SignInModal from './SignInModal';
import SignUpModal from './SignUpModal'; // Import SignUpModal

const Hero = () => {
  const [showSignInModal, setShowSignInModal] = useState(false);
  const [showSignUpModal, setShowSignUpModal] = useState(false);

  const openSignInModal = () => {
    setShowSignUpModal(false); // Close SignUpModal
    setShowSignInModal(true); // Open SignInModal
  };

  const openSignUpModal = () => {
    setShowSignInModal(false); // Close SignInModal
    setShowSignUpModal(true); // Open SignUpModal
  };

  return (
    <>
      {/* Hero Section */}
      <section
        className="hero flex flex-col items-center justify-center min-h-screen text-center bg-cover bg-center"
        style={{
          backgroundImage: `url('/background.jpg')`,
        }}
      >
        <img
          src="/logo.png"
          alt="InsightCore Logo"
          className="w-40 mb-6"
        />
        <h2 className="text-xl font-light text-gray-700 mb-6">Insight at Core</h2>
        <button
          className="px-6 py-3 bg-blue-600 text-white font-semibold text-lg rounded-lg shadow-lg hover:bg-blue-700 transition"
          onClick={openSignInModal}
        >
          LOGIN/SIGNUP
        </button>
      </section>

      {/* Modals */}
      {showSignInModal && (
        <SignInModal
          onClose={() => setShowSignInModal(false)}
          openSignUpModal={openSignUpModal}
        />
      )}
      {showSignUpModal && (
        <SignUpModal
          onClose={() => setShowSignUpModal(false)}
          openSignInModal={openSignInModal}
        />
      )}
    </>
  );
};

export default Hero;
